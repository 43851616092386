<template>
  <v-row class="m-0 p-0" v-bg:b>
    <v-col cols="12" lg="8" class="my-0 px-1 p-0">
      <v-row>
        <v-col cols="12" class="m-0" >
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="4" class="my-0 px-2 p-0">
      <datasproximas />
      <cardevento />
      <cardevento />
      <cardevento />
      <cardevento />
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import postrelato from '@/views/relatos/postRelato.vue'
import datasproximas from './datasProximas.vue'
import cardevento from "@/components/duaiscomponents/cardEvento.vue"
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'

export default {
  name: "",
  components: { postrelato, datasproximas, cardevento},
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      relatos: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    rdb.ref('/curriculo/relatos').on('value', function(snapshot) {
      self.relatos = snapshot.val();
      //console.log("self.relatos",self.relatos);
    });

  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
